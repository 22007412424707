<mat-toolbar color="primary">
  <mat-toolbar-row class="d-flex flex-fill align-items-center">
    <div class="me-auto">
      <h1 matTooltip="{{ 'missionPlanning.subtitle' | translate }}">{{ "missionPlanning.title" | translate }}</h1>
    </div>
    <ng-container *ngIf="(currentUser$ | async)?.firstName; else anonymousSettingsForm">
      <button mat-icon-button class="ms-3" (click)="openUserSettings()" matTooltip="{{ 'settings' | translate }}">
        <mat-icon>account_circle</mat-icon>
      </button>
      <h3 class="d-none d-md-block">{{ (currentUser$ | async)?.firstName }} {{ (currentUser$ | async)?.lastName }}</h3>
      <button mat-icon-button class="ms-3" (click)="logout()" matTooltip="{{ 'logout' | translate }}">
        <mat-icon>logout</mat-icon>
      </button>
    </ng-container>
    <ng-template #anonymousSettingsForm>
      <mat-form-field class="ms-3">
        <mat-label>{{ 'language' | translate }}</mat-label>
        <mat-select [formControl]="language" (selectionChange)="changeLanguage($event)">
          <mat-option *ngFor="let lang of languageOptions" [value]="lang.apiKey">
            {{ lang.translationKey | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-template>
    <button mat-icon-button color="accent" class="ms-3">
      <mat-icon (click)="openSnackBarFeedback()" matTooltip="{{ 'Leave feedback' | translate }}">feedback</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

export class LayerTypeTmp {
  // Background
  static readonly ENC = {
    key: 'ENC',
    zIndex: 0
  };

  static readonly OSM = {
    key: 'OSM',
    zIndex: 0
  };

  static readonly BASE = {
    key: 'BASE',
    zIndex: 0
  };

  // Miscellaneous
  static readonly EMODNET = {
    key: 'EMODNET',
    zIndex: 100
  };

  static readonly AIS = {
    key: 'AIS',
    zIndex: 101
  };

  static readonly QGIS = {
    key: 'QGIS',
    zIndex: 102
  };

  static readonly CASINO = {
    key: 'CASINO',
    zIndex: 103
  };

  // Carrier
  static readonly SHIP = {
    key: 'SHIP',
    zIndex: 200
  };

  static readonly SPEED_VECTORS = {
    key: 'SPEED_VECTORS',
    zIndex: 201
  };

  static readonly ROUTE = {
    key: 'ROUTE',
    zIndex: 202
  };

  static readonly TRACE = {
    key: 'TRACE',
    zIndex: 203
  };

  static readonly MBS = {
    key: 'MBS',
    zIndex: 204
  };

  static readonly TRAWL = {
    key: 'TRAWL',
    zIndex: 205
  };

  static readonly MOBILE = {
    key: 'MOBILE',
    zIndex: 206
  };

  // Map specific
  static readonly C_POINTS = {
    key: 'C_POINTS',
    zIndex: 300
  };

  static readonly C_ROUTE = {
    key: 'C_ROUTE',
    zIndex: 301
  };

  static readonly MEASURE_TOOL = {
    key: 'MEASURE_TOOL',
    zIndex: 302
  };

  static readonly SHIP_MEASURE_TOOL = {
    key: 'SHIP_MEASURE_TOOL',
    zIndex: 303
  };

  static readonly GRATICULE = {
    key: 'GRATICULE',
    zIndex: 1000
  };

  private constructor() {}
}
export enum LayerType {
    ENC = "ENC",
    OSM = "OSM",
    BASE = "BASE",
    EMODNET = "EMODNET",
    AIS = "AIS",
    C_POINTS = "C_POINTS",
    C_ROUTE = "C_ROUTE",
    CASINO = "CASINO",
    SHIP = "SHIP",
    SPEED_VECTORS = "SPEED_VECTORS",
    ROUTE = "ROUTE",
    TRACE = "TRACE",
    MBS = "MBS",
    TRAWL = "TRAWL",
    MOBILE = "MOBILE",
    GRATICULE = "GRATICULE",
    MEASURE_TOOL = "MEASURE_TOOL",
    SHIP_MEASURE_TOOL = "SHIP_MEASURE_TOOL"
}

import { CoordinatesDisplayEnum, LanguagesEnum } from './user-settings-helper';
import { UserSettingsDTO } from './dto/user-settings-interface.dto.model';

interface UserSettingsInterface {
  language: LanguagesEnum;
  coordinatesDisplay: CoordinatesDisplayEnum;
}

export class UserSettings implements UserSettingsInterface {
  language: LanguagesEnum;
  coordinatesDisplay: CoordinatesDisplayEnum;

  constructor(dtoSettings?: UserSettingsDTO) {
    this.language = dtoSettings && !!dtoSettings.language ? LanguagesEnum[dtoSettings?.language] : LanguagesEnum.FR;
    this.coordinatesDisplay =
      dtoSettings && !!dtoSettings.coordinatesDisplay
        ? CoordinatesDisplayEnum[dtoSettings?.coordinatesDisplay]
        : CoordinatesDisplayEnum.LON;
  }
}

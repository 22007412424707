import { LanguagesEnum, CoordinatesDisplayEnum } from '@core/models/user/user-settings-helper';
import { UserSettings } from '@core/models/user/user-settings-interface.model';

export interface UserSettingsInterfaceDTO {
  language: string;
  coordinatesDisplay: string;
}

export class UserSettingsDTO implements UserSettingsInterfaceDTO {
  language: string;
  coordinatesDisplay: string;

  constructor(model: UserSettings) {
    this.language = LanguagesEnum[model.language];
    this.coordinatesDisplay = CoordinatesDisplayEnum[model.coordinatesDisplay];
  }
}

// === Import: EXTERNAL
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

// === Import: LOCAL - Services
import { AuthService } from '@core/services/auth.service';

// === Import: LOCAL - Models
import { Login } from '@core/models/user/login-interface.model';
import { SnackBarService } from '@core/services/snack-bar.service';
import { TitleService } from '@core/services/title.service';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public hide = true;
  public formGroup: FormGroup;

  private username: FormControl;
  private password: FormControl;

  constructor(
    private authService: AuthService,
    private router: Router,
    private snackBarService: SnackBarService,
    private translateService: TranslateService,
    private titleService: TitleService
  ) {
    this.username = new FormControl('', Validators.required);
    this.password = new FormControl('', Validators.required);
    this.formGroup = new FormGroup({
      username: this.username,
      password: this.password
    });
  }

  ngOnInit(): void {
    this.translateService.get('login').subscribe(title => this.titleService.setTitle(title));
  }

  /**
   * Logs the user and receives the access token in auth header
   */
  public login() {
    const user: Login = this.formGroup.value;

    if (user.username && user.password) {
      this.authService.login(user).subscribe(
        (res) => {
          const accessToken: string = res.headers.get('Authorization').replace('Bearer ', '');
          localStorage.setItem('accessToken', accessToken);
          // Navigate to missions list
          this.router.navigate(['missions']);
        },
        () => {
          this.snackBarService.open(this.translateService.instant('errors.401.invalidCredentials'));
        }
      );
    }
  }
}

<ifr-main-container>
  <div class="title">
    <h1 class="mat-headline color-primary">{{ 'missionPlanning.subtitle' | translate }}</h1>
  </div>
  <div class="container">
    <mat-card>
      <mat-card-header class="color-primary">
        <mat-card-title>{{ 'login' | translate }}</mat-card-title>
        <mat-card-subtitle></mat-card-subtitle>
      </mat-card-header>

      <mat-card-content>
        <form class="form-container" [formGroup]="formGroup" novalidate (ngSubmit)="login()">
          <!-- User -->
          <mat-form-field>
            <mat-label>{{ 'username' | translate }}</mat-label>
            <input formControlName="username" matInput autofocus />
          </mat-form-field>
          <!-- Password -->
          <mat-form-field>
            <mat-label>{{ 'password' | translate }}</mat-label>
            <input formControlName="password" matInput [type]="hide ? 'password' : 'text'" />
            <button mat-icon-button matSuffix (click)="hide = !hide" type="button">
              <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
            </button>
          </mat-form-field>

          <button [disabled]="!formGroup.valid" mat-raised-button type="submit" color="primary">
            {{ 'login' | translate }}
          </button>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</ifr-main-container>

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { defaultEnv } from '@env/environment.default';

export const environment = {
  ...defaultEnv,
  atSea: false,
  inStationDistance: 0.2, // in nautical miles
  production: true,
  mapBackgroundUrl: document.location.protocol+'//'+document.location.host+'/mapproxy/tiles/mission-planning-bg/EPSG3857/{z}/{x}/{y}.png',
  wmsDefault: [
    {
      label: 'mission-planning',
      url: document.location.protocol+'//'+document.location.host+'/mapproxy/service'
    }
  ],
  ...window['mp-environment']
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

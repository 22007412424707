<!-- Title -->
<h2 class="mat-title" color="primary">{{ confirmModalData?.title | translate }}</h2>

<!-- Message -->
<div class="mat-body-1">{{ confirmModalData?.message | translate: confirmModalData?.messageParams }}</div>

<!-- No way back submessage -->
<div *ngIf="confirmModalData.noWayBack" class="mat-body-2 color-warn">{{ 'noWayBack' | translate }}</div>

<!-- Buttons -->
<div class="action-buttons">
  <button *ngIf="confirmModalData?.cancelButton" mat-raised-button mat-dialog-close (click)="emitChoice(false)">
    {{ confirmModalData?.cancelText | translate }}
  </button>
  <button mat-raised-button mat-dialog-close color="primary" (click)="emitChoice(true)">
    {{ confirmModalData?.confirmText | translate }}
  </button>
</div>

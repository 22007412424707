// === Import: EXTERNAL
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

// === Import: LOCAL
import { IfrSettingsDialogComponent as IfrUserSettingsDialogComponent } from './ifr-settings-dialog/ifr-user-settings-dialog.component';
import { AuthService } from '@core/services/auth.service';
import { UserSettings } from '@core/models/user/user-settings-interface.model';
import { SnackBarService } from '@core/services/snack-bar.service';
import { UserService } from '@core/services/user.service';
import { User } from '@core/models/user/user-interface.model';
import { EnumToTranslationKey, LanguagesEnum } from '@core/models/user/user-settings-helper';
import { BehaviorSubject } from 'rxjs';
import { MapService } from '@core/services/map.service';
import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';

@Component({
  selector: 'ifr-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
  currentUser$: BehaviorSubject<User>;
  languageOptions: EnumToTranslationKey[];
  language = new FormControl();

  constructor(
    public authService: AuthService,
    public dialog: MatDialog,
    private translateService: TranslateService,
    private router: Router,
    private userService: UserService,
    private snackBarService: SnackBarService,
    private dateAdapter: NgxMatDateAdapter<any>,
    private translate: TranslateService,
    private mapService: MapService
  ) {}

  ngOnInit(): void {
    // Set default value of the language dropdown
    this.languageOptions = IfrUserSettingsDialogComponent.getDropdownOptions(LanguagesEnum);
    this.currentUser$ = this.userService.user$;
    if (this.authService.isAuthenticated() && this.userService.user?.username == null) {
      this.userService.getCurrentUser().toPromise();
    }
    // Set default language
    let userLang = ( localStorage.getItem('selectedLanguage') || this.translateService.getBrowserLang() ).toLocaleUpperCase();
    // Get current user (or anonymous) and set language settings
    this.userService.user$.subscribe((user) => {
      if (user?.username) {
          // Set user language preference
          userLang = LanguagesEnum[user.userSettings.language];
        }
        this.translateService.use(userLang.toLowerCase()).subscribe(
          () => {
            this.language.setValue(LanguagesEnum[userLang]);
          }
        );
      this.dateAdapter.setLocale(userLang.toLowerCase());
      });
  }

  /**
   * Logout and come back to login page
   */
  public logout() {
    this.authService.logout().subscribe();
    this.userService.user = null;
    localStorage.removeItem('accessToken');
    this.translateService.use(this.translateService.getDefaultLang());
    this.router.navigate(['/login']);
  }

  /**
   * Opens the settings dialog
   * Sends the new settings to the backend
   * Applies new language in real time
   */
  public openUserSettings() {
    const dialogRef = this.dialog.open(IfrUserSettingsDialogComponent, { data: this.currentUser$.value, disableClose: true });
    dialogRef.afterClosed().subscribe((result: UserSettings) => {
      if (!result) return;
      this.currentUser$.value.userSettings = result;
      // Setting the new language in real time
      if (this.authService.isAuthenticated()) {
        this.userService.user.userSettings = result;
        this.userService.user = this.userService.user;
        this.mapService.showWaypointsList = false; // TODO changing user's language changes the language in route-detail only at the second modification ??
        this.mapService.updateSize();
        this.userService.saveUser(this.userService.user).subscribe(
          () => {
            this.setNewSettings(result);
          },
          (error: HttpErrorResponse) => {
            // TODO ErrorHandler from Angular
            this.snackBarService.open(this.translate.instant(`errors.${error.status}.${error.error.message}`));
          }
        );
      } else {
        this.setNewSettings(result);
      }
    });
  }

  private setNewSettings(userSettings: UserSettings) {
    const newLang = LanguagesEnum[userSettings.language].toString().toLowerCase();
    this.currentUser$.value.userSettings = userSettings;

    // Setting the locale to have it for CustomDateAdapter
    this.dateAdapter.setLocale(newLang);

    // Setting the new language in real time
    this.translateService.use(newLang).subscribe(
      () => {
        this.snackBarService.open(this.translateService.instant('settingsUpdated'));
      },
      () => console.log(`Error setting language ${newLang}`)
    );
  }

  /**
   * Change the app language base
   * @param event MatSelectChange, Event emitted when the selected value has been changed by the user.
   */
  public changeLanguage(event) {
    // convert back the value of the enum into language and set to lowercase to be use in service
    this.translateService.use(LanguagesEnum[event.value].toLowerCase()).subscribe(
      () => {
        // store the selected language so it's keeped for an anonymous user or user from ldap without defined settings
        localStorage.setItem('selectedLanguage', LanguagesEnum[event.value]);
      }
    );
  }

  public openSnackBarFeedback() {
    this.snackBarService.openFeedback();
  }
}

<h1 mat-dialog-title class="color-primary">{{ 'settings' | translate }}</h1>
<div mat-dialog-content>
  <form class="form-container" [formGroup]="formGroup" novalidate>
    <!-- Language options-->
    <mat-form-field>
      <mat-label>{{ 'language' | translate }}</mat-label>
      <mat-select formControlName="language" disableOptionCentering>
        <mat-option *ngFor="let lang of languageOptions" [value]="lang.apiKey">
          {{ lang.translationKey | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Coordinates modes -->
    <mat-form-field>
      <mat-label>{{ 'coordinatesDisplay' | translate }}</mat-label>
      <mat-select formControlName="coordinatesDisplay" disableOptionCentering>
        <mat-option *ngFor="let coord of coordinatesOptions" [value]="coord.apiKey">
          {{ coord.translationKey | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>

<!-- Actions -->
<div mat-dialog-actions class="action-buttons">
  <!-- Cancel -->
  <button mat-raised-button color="warn" (click)="cancel()" type="button" cdkFocusInitial>
    {{ 'cancel' | translate }}
  </button>
  <!-- Save: sends the formGroup.value -->
  <button [disabled]="!formGroup.valid" mat-raised-button color="primary" [mat-dialog-close]="formGroup.value">
    {{ 'save' | translate }}
  </button>
</div>

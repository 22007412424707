// === Import: EXTERNAL
import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';

// === Import: LOCAL
import { LanguagesEnum, TranslationKeysEnum, CoordinatesDisplayEnum, EnumToTranslationKey } from '@core/models/user/user-settings-helper';
import { User } from '@core/models/user/user-interface.model';

@Component({
  selector: 'ifr-user-settings-dialog',
  templateUrl: './ifr-user-settings-dialog.component.html',
  styleUrls: ['./ifr-user-settings-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IfrSettingsDialogComponent implements OnInit {
  // Form
  public formGroup: FormGroup;
  public language: FormControl;
  public coordinatesDisplay: FormControl;

  // Dropdown options
  public languageOptions: EnumToTranslationKey[];
  public coordinatesOptions: EnumToTranslationKey[];

  // TODO move method to service or whatever
  /**
   *
   * @param enumName name of the enum for which we want to get the options to display in the dropdown
   */
  static getDropdownOptions(enumName: typeof LanguagesEnum | typeof CoordinatesDisplayEnum): EnumToTranslationKey[] {
    return Object.keys(enumName)
      .filter((key) => isNaN(Number(key)))
      .map((value, key) => {
        return { apiKey: key, translationKey: TranslationKeysEnum[value] };
      });
  }

  constructor(
    public dialogRef: MatDialogRef<IfrSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public currentUser: User
  ) {
    this.language = new FormControl('', Validators.required);
    this.coordinatesDisplay = new FormControl('', Validators.required);
    this.formGroup = new FormGroup({
      language: this.language,
      coordinatesDisplay: this.coordinatesDisplay
    });
  }

  ngOnInit() {
    // Set dropdown options from the enums
    this.languageOptions = IfrSettingsDialogComponent.getDropdownOptions(LanguagesEnum);
    this.coordinatesOptions = IfrSettingsDialogComponent.getDropdownOptions(CoordinatesDisplayEnum);
    this.formGroup.patchValue({
      language: this.currentUser.userSettings.language,
      coordinatesDisplay: this.currentUser.userSettings.coordinatesDisplay
    });
  }

  /**
   * Closes the dialog without doing any other action
   */
  public cancel() {
    this.dialogRef.close();
  }
}

import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { SnackBarService } from '@core/services/snack-bar.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ifr-shared-mission-modal',
  templateUrl: './shared-mission-modal.component.html',
  styleUrls: ['./shared-mission-modal.component.scss']
})
export class SharedMissionModalComponent implements OnInit {
  firstFormGroup: FormGroup;
  sharedId: string;
  sharedLink: string;

  constructor(
    public dialogRef: MatDialogRef<SharedMissionModalComponent>,
    private formBuilder: FormBuilder,
    private snackBarService: SnackBarService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    // create shareLink
    this.sharedLink = `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? `:${window.location.port}` : ''
    }/map/shared/${this.sharedId}`;
    this.firstFormGroup = this.formBuilder.group({
      sharedLink: [{ value: this.sharedLink, disabled: true }, Validators.required]
    });
  }

  // open snackbar, to confirm the copy to clipboard's success
  copy() {
    this.snackBarService.open(this.translateService.instant('missions.copy'));
  }
}

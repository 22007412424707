import { Injectable } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { defaults as defaultControls } from 'ol/control';
import {
  CustomPoints,
  CustomRoute,
  Ship,
  SpeedVectors,
} from '@fleet/ol-ext-marine/src/layer';
import { BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { QGISLoader } from '@fleet/ol-ext-marine/src/util/QgisLoader';

import { LayerType } from '../models/layers/layer-type';
import { LayerContent, LayersInterface } from '@core/models/layers/layers-interface';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { LayersDTO } from '@core/models/layers/dto/layers-interface.dto';
import { Point } from '@core/models/point/point-interface';
import { User } from '@core/models/user/user-interface.model';
import { RoutesPointsService, MapService as LibComponentMapService } from '@nse/lib-component';
import { TranslateService } from '@ngx-translate/core';
import { Waypoint } from '@core/models/waypoint/waypoint-interface';
import { transform } from 'ol/proj';
import { Point as OlPoint } from 'ol/geom';
import { GEODETIC_SYSTEM } from '@shared/constants';

@Injectable({
  providedIn: 'root'
})
export class MapService extends LibComponentMapService {
  private LAYERS_URL = 'layers';
  private QGIS_URL = 'qgis';
  private speedVectorLayer: SpeedVectors;
  public layers$: BehaviorSubject<LayersInterface>;
  public points$: BehaviorSubject<Point[]>;
  public showWaypointsList$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  get layers(): LayersInterface {
    return this.layers$.getValue();
  }

  set layers(value: LayersInterface) {
    this.layers$.next(value);
  }

  get points(): Point[] {
    return this.points$.getValue();
  }

  set points(value: Point[]) {
    this.points$.next(value);
  }

  get showWaypointsList(): boolean {
    return this.showWaypointsList$.getValue();
  }

  set showWaypointsList(value: boolean) {
    this.showWaypointsList$.next(value);
  }

  constructor(
    private eventManager: EventManager,
    private httpClient: HttpClient,
    private routesPointsService: RoutesPointsService,
    private translateService: TranslateService,
  ) {
    super(eventManager);
    this.layers$ = new BehaviorSubject<LayersInterface>(null);
    this.points$ = new BehaviorSubject<Point[]>(null);
  }

  public addMissionPlanningDefaultControls() {
    if (environment.atSea === true) {
      this.speedVectorLayer = this.createLayer(LayerType.SPEED_VECTORS);
    }
    this.map.set('controls', defaultControls);
    this.measureToolLayer.orthodromic = true;
    this.shipMeasureToolLayer.orthodromic = true;
  }

  public updateSize() {
    setTimeout(() => {
      this.map.updateSize();
    }, 0);
  }


  /**
   * Returns all the layers belonging to the user or default layers
   * if user does not have any layers defined
   */
  public getLayers(customPointLayer: CustomPoints, customRouteLayer: CustomRoute, user: User) {
    return this.httpClient.get(`${environment.apiURL}/${environment.protectedURL}/${this.LAYERS_URL}`).pipe(
      map((data: LayersDTO) => {
        if (!!data) {
          return (this.layers = {
            // create the default base layers
            base: [
              new LayerContent(data.base[0].name, this.createLayer(LayerType.SHIP), data.base[0].isDisplayed, this.translateService.instant('layers.layername.ship')),
              new LayerContent(data.base[1].name, customPointLayer, data.base[1].isDisplayed, this.translateService.instant('layers.layername.mark')),
              new LayerContent(data.base[2].name, customRouteLayer, data.base[2].isDisplayed, this.translateService.instant('layers.layername.route'))
            ],
            wms: this.buildLayers(data.wms),
            qgis: this.buildLayers(data.qgis)
          });
        } else {
          throw new Error(); // throw an error so catchError can catch it and return default layers
        }
      }),
      catchError((err) => {
        this.layers = {
          base: [
            new LayerContent('Ship', this.createLayer(LayerType.SHIP), true, this.translateService.instant('layers.layername.ship')),
            new LayerContent('Marks', customPointLayer, true, this.translateService.instant('layers.layername.mark')),
            new LayerContent('Route', customRouteLayer, true, this.translateService.instant('layers.layername.route'))
          ],
          wms: {},
          qgis: {}
        };
        return this.layers$;
      })
    );
  }

  /**
   * Instantiate all layers with ol-ext-marine based on data received from the backend
   * @param data data (wms or qgis) receive from the backend
   */
  private buildLayers(data) {
    const layers = {};
    Object.keys(data).forEach((value) => {
      const loader = new QGISLoader(data[value].url);
      layers[value] = {
        url: data[value].url,
        layers: data[value].subLayers.map((element) => {
          return new LayerContent(
            element.name,
            loader.instantiateLayers(element.layerName, data[value].url),
            element.isDisplayed,
            element.layerName
          );
        }),
        default: !!environment.wmsDefault.filter(wms => wms.label === value).length
      };
    });
    return layers;
  }

  /**
   * Add or remove a layer based on his isDisplayed prop, layer.content contained the OpenLayer layer instance which is used to remove or add the layer
   * @param layer layerInterface
   */
  public addOrRemoveLayer(layer: LayerContent) {
    if (!layer.isDisplayed && this.hasLayer(layer.content)) {
      this.map.removeLayer(layer.content);
    } else if (layer.isDisplayed && !this.hasLayer(layer.content)) {
      this.map.addLayer(layer.content);
    }
  }

  public addOrRemoveSpeedVectorLayer() {
    if (!environment.atSea) {
      return;
    }

    if (this.hasLayerType(LayerType.SHIP)) {
      this.map.addLayer(this.speedVectorLayer);
    } else {
      this.map.removeLayer(this.speedVectorLayer);
    }
  }

  /**
   * Add all (and remove specific) custom layer to the map when first init the map
   */
  public addCustomLayer() {
    this.layers$.value.base.forEach((l) => {
      if (l.isDisplayed) {
        if (!(l.content instanceof Ship) || environment.atSea) {
          try {
            this.map.addLayer(l.content);
          } catch (e) {}
        }
      } else if (this.map.getLayers().getArray().filter(layer => layer === l.content).length > 0) {
        this.map.removeLayer(l.content);
      }
    });
    if (Object.keys(this.layers$.value.wms).length > 0) {
      Object.keys(this.layers$.value.wms).forEach((value) => {
        this.layers$.value.wms[value].layers.forEach((l) => {
          if (l.isDisplayed) {
            try {
              this.map.addLayer(l.content);
            } catch (e) {}
          }
        });
      });
    }
    if (Object.keys(this.layers$.value.qgis).length > 0) {
      Object.keys(this.layers$.value.qgis).forEach((value) => {
        this.layers$.value.qgis[value].layers.forEach((l) => {
          if (l.isDisplayed) {
            try {
              this.map.addLayer(l.content);
            } catch (e) {}
          }
        });
      });
    }

    this.addOrRemoveSpeedVectorLayer();
  }

  /**
   * Convert node received from ol-ext-marine in layerInterface used for the drawer and add it to our observable
   * @param node data received from ol-ext-marine
   * @param key layer family key
   */
  public addLayersNode(node, key) {
    this.layers[key] = {
      [node.label]: {
        url: node.url,
        layers: node.layersConfig.map((element) => {
          const loader = new QGISLoader(node.url);
          return new LayerContent(element.name, loader.instantiateLayers(element.layerName, node.url), false, element.layerName);
        }),
        default: node.default
      },
      ...this.layers[key]
    };
  }

  /**
   * Delete layer used by the drawer
   * @param label Layer label to be deleted
   * @param key layer family key
   */
  public deleteLayersNode(label, key) {
    this.layers[key][label].layers.forEach(layer => this.map.removeLayer(layer.content));
    delete this.layers[key][label];
  }

  /**
   * Sends QGIS zip to backend
   */
  public uploadQgis(formData: FormData) {
    return this.httpClient.post(environment.apiURL + '/' + environment.protectedURL + '/' + this.QGIS_URL, formData, {
      responseType: 'text'
    });
  }

  /**
   * Gets wms xml from specified url
   * @param url WMS server url
   */
  public getWms(url) {
    const params = new HttpParams({fromObject: {
      'service': 'WMS',
      'request': 'GetCapabilities',
      'version': '1.3.0'
    }});
    return this.httpClient.get(url, {
      params,
      responseType: 'text'
    });
  }

  public getLayersFromResponse(response) {
    const loader = new QGISLoader();
    const layersList = loader.getLayersFromXml({ data: response });
    return layersList.reverse().map((layer) => ({
      name: layer.Title,
      layer: loader.instantiateLayers(layer.Name, layer.url),
      layerName: layer.Name,
      url: layer.url
    }));
  }

  public fitZoom() {
    if (!this.routesPointsService.customRouteLayer) {
      return;
    }
    const extent = this.routesPointsService.customRouteLayer.getRouteExtent();
    if (extent !== null) {
      const view = this.map.getView();
      view.fit(extent);
      const zoom = view.getZoom();
      view.setZoom(Math.max(0, zoom - 1));
    }
  }

  /**
   * Center view on point
   * @param point Point to center on
   * @param duration The duration of the animation in milliseconds. Default is 250.
   */
  public centerOnPoint(point: Point | Waypoint, duration: number | undefined = 250) {
    const view = this.map.getView();
    const coordinates = transform([point.longitude, point.latitude], GEODETIC_SYSTEM, view.getProjection());
    view.fit(
      new OlPoint(coordinates),
      { duration, maxZoom: view.getZoom() }
    );
  }
}

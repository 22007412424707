import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Login } from '@core/models/user/login-interface.model';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(private httpClient: HttpClient) {}

  public isAuthenticated(): boolean {
    return !!localStorage.getItem('accessToken');
  }

  /**
   * Sends a login request to backend using Basic auth method.
   * username:password is encoded in base64 and given to backend through the Authorization header
   * @param user
   */
  public login(user: Login): Observable<HttpResponse<Login>> {
    let headers: HttpHeaders = new HttpHeaders();
    const authHeader = 'Basic ' + btoa(`${user.username}:${user.password}`);
    headers = headers.set('Authorization', authHeader);
    return this.httpClient.post<Login>(`${environment.apiURL}/login`, null, { headers, observe: 'response' });
  }

  public logout() {
    return this.httpClient.post<any>(`${environment.apiURL}/logout`, null);
  }

  public refresh() {
    return this.httpClient.post<any>(`${environment.apiURL}/refresh`, null, { observe: 'response' });
  }
}

export const IS_NUMBER = /^\d*$/;
export const IS_NUMBER_OR_FLOAT = /^\d+(\.\d+)?$/;
export const DEFAULT_TRANSIT_SPEED = 11;
export const IS_COORDINATE = /^(\d{1,3}\°\d{1,2}(\.\d{1,3})?[′'][NWES]|-?[0-9]{1,3}(\.[0-9]+)?)$/;
export const IS_DECIMAL_COORDINATE = /^-?[0-9]{1,3}(\.[0-9]+)?$/;
export const IS_DM_COORDINATE = /^\d{1,3}\°\d{1,2}(\.\d{1,3})?[′'][NWES]$/;

export const MERCATOR_PROJECTION = 'EPSG:3857';
export const GEODETIC_SYSTEM = 'EPSG:4326';

export const MILES_TO_KM = 1.852;

export const IS_VALID_NAME = /^[a-zA-Z0-9 _()-]+$/;

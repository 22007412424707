import { Component, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ifr-upload-file',
  exportAs: 'uploadFile',
  template: `
    <input
      type="file"
      #fileInput
      style="display: none"
      (click)="onClick($event)"
      (change)="onChange()"
      multiple="false"
      [accept]="accept"
    />
    <ng-content></ng-content>
  `
})
export class UploadFileComponent {
  @Input()
  public accept: string;

  @ViewChild('fileInput', { static: true })
  public fileInput: ElementRef;

  @Output()
  public fileSelected = new EventEmitter();

  public browse() {
    this.fileInput.nativeElement.click();
  }

  public onClick(event) {
    event.target.value = null;
  }

  public onChange() {
    if (this.fileInput.nativeElement.files && this.fileInput.nativeElement.files.length > 0) {
      this.fileSelected.emit(this.fileInput.nativeElement.files[0]);
    }
    return false;
  }
}

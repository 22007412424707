import { Layer as OLayer } from 'ol/layer/Layer';

export interface LayerInterface {
  label: string;
  content: OLayer;
  isDisplayed: boolean;
  layerName: string;
}

export class LayerContent implements LayerInterface {
  label: string;
  content: OLayer;
  isDisplayed: boolean;
  layerName: string;

  constructor(label: string, content: OLayer, isDisplayed: boolean, layerName: string) {
    this.label = label;
    this.content = content;
    this.isDisplayed = isDisplayed || false;
    this.layerName = layerName;
  }
}

export type LayerType = {
  [label: string]: {
    url: string;
    layers: LayerInterface[];
  };
};

export interface LayersInterface {
  base: LayerContent[];
  wms: LayerType;
  qgis: LayerType;
}

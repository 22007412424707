import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from '@core/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class NotLoggedInGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate() {
    let isTokenPresent = this.authService.isAuthenticated();

    // if there's no access token found, redirect user to login page
    if (!isTokenPresent) {
      this.router.navigate(['/login']);
    }
    return isTokenPresent;
  }
}

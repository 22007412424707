// Import -- EXTERNAL
import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

// Import -- LOCAL
import { ConfirmModalData } from '@core/models/confirm-modal/confirm-modal-interface';

@Component({
  selector: 'ifr-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmModalComponent implements OnInit {
  @Input() confirmModalData: ConfirmModalData;

  constructor(public dialogRef: MatDialogRef<ConfirmModalComponent>) {}

  ngOnInit(): void {}

  public emitChoice(choice: boolean) {
    this.dialogRef.close(choice);
  }
}

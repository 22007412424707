//  Import -- EXTERNAL
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

//  Import -- LOCAL
import { environment } from '@env/environment';
import { User } from '@core/models/user/user-interface.model';
import { of, Observable, BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { UserDTO } from '@core/models/user/dto/user-interface.dto.model';
import { TranslateService } from '@ngx-translate/core';
import { LanguagesEnum } from '@core/models/user/user-settings-helper';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public user$: BehaviorSubject<User>;
  private LAYERS_URL = 'layers';

  get user(): User {
    return this.user$.getValue();
  }

  set user(value: User) {
    this.user$.next(value);
  }

  constructor(private httpClient: HttpClient, private translateService: TranslateService, private authService: AuthService) {
    this.user$ = new BehaviorSubject<User>(null);
  }


  /**
   * Gets the current connected user through a call to the backend
   */
  public getCurrentUser(): Observable<User> {
    return this.httpClient.get<UserDTO>(`${environment.apiURL}/${environment.protectedURL}/user`).pipe(
      map((data: UserDTO) => {
        return (this.user = this.getUserAndSetLanguage(data));
      }),
      catchError((err) => {
        return of((this.user = this.getUserAndSetLanguage()));
      })
    );
  }

  /**
   * Sends user's new layers settings to backend
   */
  public updateUserLayers(layers: string) {
    if (this.authService.isAuthenticated()) {
      this.httpClient.put<string>(`${environment.apiURL}/${environment.protectedURL}/${this.LAYERS_URL}`, layers).subscribe();
    }
  }

  /**
   * Sends the new user to the backend
   * @param user the user with the settings and properties to be updated
   */
  public saveUser(user: User): Observable<UserDTO> {
    const dtoUser: UserDTO = new UserDTO(user);
    dtoUser.username = null;
    return this.httpClient.put<UserDTO>(`${environment.apiURL}/${environment.protectedURL}/user`, dtoUser);
  }

  /**
   * Instantiates a user from received data, and sets the language with default value if empty
   */
  private getUserAndSetLanguage(data?: UserDTO): User {
    const user = new User(data);
    if (user.userSettings.language == null) {
      user.userSettings.language = LanguagesEnum[localStorage.getItem('selectedLanguage')];
    } else {
      localStorage.removeItem('selectedLanguage'); // Get rid of the stored language if user has alread defined settings
    }
    return user;
  }
}

// === Import: EXTERNAL
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localeFr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';
import { StompRService } from '@stomp/ng2-stompjs';

// === Import: LOCAL - Components
import { IfrComponent } from './ifr.component';
import { LoginComponent } from './login/login.component';
// === Import: LOCAL - Modules
import { SharedModule } from '@shared/shared.module';

// === Import: LOCAL - Others
import { environment } from '@env/environment';
import { IfrRoutingModule } from './ifr-routing.module';
import { CoreModule } from './core/core.module';
import { LoginInterceptor } from './login/login-interceptor';

// Register the locale we want to use other than 'en'
// the second parameter 'fr' is optional
registerLocaleData(localeFr, 'fr');

/* For ngx translate */
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const FEATURE_COMPONENTS = [LoginComponent];

@NgModule({
  declarations: [IfrComponent, FEATURE_COMPONENTS],
  imports: [
    BrowserModule,
    HttpClientModule,
    CoreModule,
    SharedModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'fr',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    IfrRoutingModule
  ],
  providers: [
    StompRService,
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: HTTP_INTERCEPTORS, useClass: LoginInterceptor, multi: true }
  ],
  exports: [SharedModule],
  bootstrap: [IfrComponent]
})
export class IfrModule {}

// This file can be import inside custom environment configuration :
// `import {default} from 'default';` to set default environment values.
// ```
// export const environment = {
//   ...default,
//   production: true
// };
// ```

export const defaultEnv = {
  apiURL: '/api/v1',
  protectedURL: 'protected',
  production: false,
  atSea: true, // boolean
  inStationDistance: 2, // in nautical miles
  shipSpeedHistoryPeriod: 10, // in seconds
  // mapBackgroundUrl: document.location.protocol+'//'+document.location.host+'/mapproxy/tiles/mission-planning-bg/EPSG3857/{z}/{x}/{y}.png', // XYZ url
  wmsDefault: [
    {
      label: 'Espaces maritimes français',
      url: 'https://services.data.shom.fr/DELMAR/wms/v'
    },
    {
      label: 'Marine Regions',
      url: 'https://geo.vliz.be/geoserver/MarineRegions/wms'
    }
  ],
  qgisServerURL: '' // Url of the QGIS server to target
};

import { UserSettingsDTO } from './user-settings-interface.dto.model';
import { UserInterface } from '@core/models/user/user-interface.model';

export interface UserInterfaceDTO {
  username: string;
  firstName: string;
  lastName: string;
  userSettings: UserSettingsDTO;
}

export class UserDTO implements UserInterfaceDTO {
  username: string;
  firstName: string;
  lastName: string;
  userSettings: UserSettingsDTO;

  constructor(model: UserInterface) {
    this.username = model.username;
    this.firstName = model.firstName;
    this.lastName = model.lastName;
    this.userSettings = new UserSettingsDTO(model.userSettings);
  }
}

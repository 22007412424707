import { Injectable, Optional, Inject } from '@angular/core';
import moment from 'moment';
import {
  NgxMatMomentAdapter,
  NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  NgxMatMomentDateAdapterOptions
} from '@angular-material-components/moment-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxMatDateFormats } from '@angular-material-components/datetime-picker';

// D can be Date, Moment or customized type
@Injectable({ providedIn: 'root' })
export class CustomDateAdapter extends NgxMatMomentAdapter {
  constructor(
    @Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string,
    @Optional() @Inject(NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS) private options?: NgxMatMomentDateAdapterOptions
  ) {
    super(dateLocale, options);
  }

  /**
   *
   * @param value The input date
   * @param parseFormat The format to output the date
   */
  public format(value: moment.Moment, parseFormat: string): any {
    return super.format(value, 'L LT');
  }

  /**
   *
   * @param value The input date
   * @param parseFormat The format to output the date
   */
  public parse(value: moment.Moment, parseFormat: string): moment.Moment {
    return super.parse(value, 'L LT');
  }
}

// If using Moment
export const CUSTOM_MOMENT_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: 'L, LT'
  },
  display: {
    dateInput: 'L, LT',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MM YYYY'
  }
};

export enum LanguagesEnum {
  FR,
  EN
}

export enum CoordinatesDisplayEnum {
  LON,
  DEC
}

// Relation between the enums and the keys in the translation files (Ex. fr.json)
export enum TranslationKeysEnum {
  EN = 'languageOption.en',
  FR = 'languageOption.fr',
  LON = 'coordinatesOptions.longitudinal',
  DEC = 'coordinatesOptions.decimal'
}

export type EnumToTranslationKey = {
  apiKey: number;
  translationKey: string;
};

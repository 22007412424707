import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  constructor(
    private title: Title,
    private translateService: TranslateService
  ) {}

  setTitle(title: string) {
    this.translateService.get('missionPlanning.title').subscribe(mpTitle => this.title.setTitle(`${title} | ${mpTitle}`));
  }

  getTitle(): string {
    return this.title.getTitle();
  }
}

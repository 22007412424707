import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ifr-main-container',
  templateUrl: './main-container.component.html',
  styleUrls: ['./main-container.component.scss']
})
export class MainContainerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, flatMap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { AuthService } from '@core/services/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class LoginInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) {}

  /**
   * Intercepts any outgoing http request (except some requests, based on url) and adds the access token in the authorization header
   * @param req
   * @param next
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.match('.*/login') && !req.url.match('.*/refresh') && !req.url.match('.*/assets')) {
      req = this.addHeader(req);
      return next.handle(req).pipe(catchError((x) => this.handleResponseError(x, req, next)));
    }
    return next.handle(req);
  }

  /**
   * Handles unauthorized error responses from back end.
   * If the error is caused by the access token expiration, tries to refresh the token and re-launch the original request
   * @param err
   * @param req
   * @param next
   */
  private handleResponseError(err: HttpErrorResponse, req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (err.status === 401 && (err.error.message === 'tokenExpired' || JSON.parse(err.error).message === 'tokenExpired')) {
      localStorage.removeItem('accessToken');
      return this.authService
        .refresh()
        .pipe(
          flatMap((data: HttpResponse<any>) => {
            data.headers.keys();
            localStorage.setItem('accessToken', data.headers.get('Authorization').replace('Bearer ', ''));
            req = this.addHeader(req);
            return next.handle(req);
          })
        )
        .pipe(
          catchError((error) => {
            if (error.status === 401) {
              return this.router.navigate(['/login']);
            }
            return throwError(error);
          })
        );
    }
    return throwError(err);
  }

  private addHeader(req: HttpRequest<any>): HttpRequest<any> {
    req = req.clone({
      headers: req.headers.set('Authorization', 'Bearer ' + localStorage.getItem('accessToken'))
    });
    return req;
  }
}

// === Import: EXTERNAL
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MaterialModule } from './material-module';
import { TranslateModule } from '@ngx-translate/core';
import {
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule,
  NgxMatNativeDateModule,
  NgxMatDateAdapter,
  NGX_MAT_DATE_FORMATS
} from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular-material-components/moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_LOCALE } from '@angular/material/core';

// === Import: LOCAL - Components
import { SnackBarComponent } from './components/snackbar/snack-bar.component';
import { SnackBarFeedbackComponent } from './components/snackbar/snack-bar-feedback.component';
import { MainContainerComponent } from './components/main-container/main-container.component';
import { HeaderComponent } from './components/header/header.component';
import { IfrSettingsDialogComponent } from './components/header/ifr-settings-dialog/ifr-user-settings-dialog.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { SharedMissionModalComponent } from './components/shared-mission-modal/shared-mission-modal.component';
import { CUSTOM_MOMENT_FORMATS, CustomDateAdapter } from '@core/custom-date-adapter';
import { UploadFileComponent } from '../map/components/upload-file/upload-file.component';

const COMPONENTS = [
  SnackBarComponent,
  SnackBarFeedbackComponent,
  MainContainerComponent,
  HeaderComponent,
  SharedMissionModalComponent,
  IfrSettingsDialogComponent,
  ConfirmModalComponent,
  UploadFileComponent
];

@NgModule({
  declarations: [COMPONENTS],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    ReactiveFormsModule,
    ClipboardModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    MatDatepickerModule,
    NgxMatNativeDateModule,
    NgxMatMomentModule
  ],
  exports: [
    MaterialModule,
    TranslateModule,
    ReactiveFormsModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    MatDatepickerModule,
    COMPONENTS
  ],
  providers: [
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3500 } },
    // For date time picker
    {
      provide: NgxMatDateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_MOMENT_FORMATS }
  ]
})
export class SharedModule {}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { NotLoggedInGuard } from './login/logged-in-guard';

export const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'missions',
    loadChildren: () => import('./missions/missions.module').then((m) => m.MissionsModule),
    canActivate: [NotLoggedInGuard]
  },
  {
    path: 'map',
    loadChildren: () => import('./map/map.module').then((m) => m.MapModule)
  },
  { path: '**', redirectTo: 'missions' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class IfrRoutingModule {}

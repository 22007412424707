import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackBarComponent, SnackBarFeedbackComponent } from '@shared/components/snackbar';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {
  public snackBarHandler: MatSnackBarRef<any>;

  constructor(private snackBar: MatSnackBar) {}

  open(message: string) {
    // Open snackbar with the error
    return this.setSnackBarHandler(
      this.snackBar.openFromComponent(SnackBarComponent, {
        data: message
      })
    );
  }

  openFeedback() {
    return this.setSnackBarHandler(
      this.snackBar.openFromComponent(SnackBarFeedbackComponent, {
        duration: 0
      })
    );
  }

  dismiss() {
    if (this.snackBarHandler) this.snackBarHandler.dismiss();
  }

  setSnackBarHandler(snackBarHandler: MatSnackBarRef<any>) {
    this.snackBarHandler = snackBarHandler;
    this.snackBarHandler.afterDismissed().subscribe(() => {
      this.snackBarHandler = undefined;
    });
    return snackBarHandler;
  }
}

<div class="dialog-container">
  <div class="title">
    <h1 mat-dialog-title>{{ 'missions.sharedTitle' | translate }}</h1>
    <mat-icon mat-dialog-close>close</mat-icon>
  </div>
  <div mat-dialog-content>
    <form [formGroup]="firstFormGroup">
      <mat-form-field>
        <mat-label>{{ 'missions.sharedLink' | translate }}</mat-label>
        <input formControlName="sharedLink" matInput />
      </mat-form-field>
      <div class="d-flex justify-content-end">
        <button mat-raised-button mat-dialog-close [cdkCopyToClipboard]="sharedLink" (click)="copy()">
          {{ 'missions.copyTitle' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>

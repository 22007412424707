import { UserSettings } from './user-settings-interface.model';
import { UserDTO } from './dto/user-interface.dto.model';

export interface UserInterface {
  username: string;
  firstName: string;
  lastName: string;
  userSettings: UserSettings;
}

export class User implements UserInterface {
  username: string;
  firstName: string;
  lastName: string;
  userSettings: UserSettings;

  constructor(dto?: UserDTO) {
    this.username = dto?.username;
    this.firstName = dto?.firstName;
    this.lastName = dto?.lastName;
    this.userSettings = new UserSettings(dto?.userSettings);
  }
}

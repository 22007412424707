import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'ifr-snackbar-feedback',
  templateUrl: './snack-bar-feedback.component.html',
  styleUrls: ['./snack-bar-feedback.component.scss'],
})
export class SnackBarFeedbackComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    public snackBarRef: MatSnackBarRef<SnackBarFeedbackComponent>
  ) {
    this.snackBarRef.onAction().subscribe(() => {
      this.mailto_();
    })
  }

  private mailto_() {
    document.location.href = 'mailto:techsas@ifremer.fr?subject=Mission Planning';
    this.snackBarRef.dismiss();
  }
}
